<template>
<TopBar/>
<Header/>
<NavBar/>

  <section>
      <div class="container">
          <div class="breadcrumbs">
              <ul class="background">
                  <li> <router-link to = "/">Home</router-link> </li> <span class="arrow">></span>
                  <li>About Us</li><span class="arrow">></span>
                  <li>{{title}}</li>
              </ul>
          </div>
          <div class="page">
              <div class="page-content">
                  <h1>{{title}}</h1>
                  <ol class="rounded-list">
                        <li>Retd. Col. S. Majumdar | 1969 – 1971</li>
                        <li>Retd. Lt. Col. P.D. Sharma	| 1972 – 1974</li>
                        <li>Mr. J.B. Tyson	| 1975 – 1976</li>
                        <li>Mr. R.D. Singh	| 1977 – 1982</li>
                        <li>Mr. K. George Ipe| 1982 – 1988</li>    
                         <li>Ms. Namgay Om	| 1988 – 1990</li>    
                          <li>Mr. Tshewang Tandin | 1990 – 1998</li>
                          <li>Mr. Karma Yeshey	| 1998 – 2003</li>  
                          <li>Mr. Kaka Tshering | 2003 – 2006</li>  
                          <li>Ms. Pema Chhoden Wangdi |	2007 – 2017</li>  
                          <li>Dr. Karma Drukpa	| 2017 – 2018</li>  
                          <li>Mr. Yesh B. Ghaley	| 2019 … till date</li>                        
                    </ol>
                  
   
   
              </div>
              
          <div class="sidebar">
              <AboutUsQuickLinks/>
              <RecentPosts/>
          </div>
          </div>
      </div>
  </section>
  <Footer/>
</template>

<script >
import TopBar from "../../components/page/TopBar.vue";
import Header from "../../components/page/Header.vue";
import NavBar from "../../components/page/NavBar.vue";
import RecentPosts from "../../components/page/RecentPosts.vue";
import { ref } from '@vue/reactivity';
import AboutUsQuickLinks from '../../components/page/AboutUsQuickLinks.vue';
import Footer from "../../components/page/Footer.vue";




export default {
    name: 'Background',    
    components: { TopBar, Header, NavBar, RecentPosts, AboutUsQuickLinks, Footer },
    setup(){
        const title= ref('Principals')
        return {title}
    }
    
  
}
</script>

<style scoped>


ol {
  counter-reset: li;
  list-style: none;
  *list-style: decimal; /* Keep using default numbering for IE6/7 */
  padding: 0 1rem;
  text-shadow: 0 1px 0 rgba(255,255,255,.5);
  width: 100%;
  column-count: 1;
  column-gap: 2rem;
 
}

.rounded-list li {
  position: relative;
  display: block;
  padding: .4em .4em .4em 2em;
  *padding: .4em;
  margin: .5em 0;
  background: #ddd;
  color: #444;
    text-decoration: none;
  border-radius: .3em;
    transition: all .5s ease-out; 
}
.rounded-list li:hover{
  background: #eee;
}


.rounded-list li:before{
  content: counter(li);
  counter-increment: li;
  position: absolute; 
  left: -1.3em;
  top: 50%;
  margin-top: -1.3em;
  background: var(--primary);
  height: 2em;
  width: 2em;
  line-height: 2em;
  border: .3em solid #fff;
  text-align: center;
  font-weight: bold;
  border-radius: 2em;
  transition: all .3s ease-out;
}
table {
  border-collapse: collapse;
  width: 100%;
}
table,td,th{
    border: 1px solid black;
    padding: 0.5rem;
}

@media (min-width:768px) {
    table {
        width: 80%;
        padding: 1rem;
    }
    ol{
        column-count: 2;
        width: 100%;
    }
}

</style>